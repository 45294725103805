.popup {
  background-color: #5f6063;
  color: #fff;
  transition: all 0.3s;
  z-index: 1000;

  &__option {
    text-transform: uppercase;
    margin: 1.5rem 0;
    font-size: 1.4rem;
    cursor: pointer;
    min-width: 9rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    &:hover {
      color: rgb(122, 178, 252);
      transition: 0.2s;
    }

    &-icon {
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }

    &-disabled {
      color: #aaa;
      cursor: not-allowed;
      &:hover {
        color: #aaa !important;
      }
    }
  }

  &.closed {
    height: 0;
    padding: 0;
    color: #fff;
    z-index: -1;

    & > div {
      display: none;
    }
  }

  &.open {
    padding: 10px 15px;
    border-radius: 10px;
    border-top-right-radius: 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
}

.input {
  position: relative;
  width: 100%;

  &__control {
    width: 100%;
    padding: 1.1rem 5rem;
    border: 0;
    background-color: #f0f2f9;
    border-radius: 10px;
    border: 1px solid #f0f2f9;
    color: #465eb5;

    &.error {
      border: 1px solid #f26065;
    }

    &:disabled {
      color: #787878;
    }
  }

  &__icon {
    position: absolute;
    top: 38px;
    left: 17px;
    &-no-title {
      position: absolute;
      top: 12.5px;
      left: 17px;
      width: 24px;
      height: 24px;
    }
  }

  &__label {
    text-transform: uppercase;
    display: block;
    font-size: 1rem;
    margin: 0px 0px 8px 0px;
    color: #777679;
  }

  &__error {
    color: $red-primary;
    font-size: 1.4rem;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);

    & + input {
      border-color: $red-primary;
    }
  }
}

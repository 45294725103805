.delete-org-modal {
  &-bold-text {
    font-family: 'Nexa Bold', sans-serif;
  }

  &-button {
    position: relative;
    margin: 25px 0px 0px;
    width: 376px;

    &.with-loader {
      height: 49.14px;
    }

    &:disabled {
      filter: opacity(0.5);
      background-color: grey;
    }

    &-loader {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      z-index: 9;
      animation: spinAnimation 1s linear infinite;

      @keyframes spinAnimation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

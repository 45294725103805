.label {
  position: relative;
  border: 0;
  margin: 0 2em 2rem 0rem;
  // cursor: url(../../assets/icons/customCursorPointer.svg), pointer;
  cursor: pointer;
  font-family: 'Nexa Regular', sans-serif;
  background-color: inherit;
  min-width: 248px;
  height: 74px;
  border-radius: 20px;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;

  &:hover {
    color: darken($blue-secondary, 5);
    background: #fbfbff;
  }

  &-title {
    position: relative;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #777679;
    &-spn {
      position: absolute;
      display: inline-block;
      height: 30px;
      width: 30px;
      z-index: 20;
      &__icon {
        margin: 0px 0px -1px 8px;
        z-index: 10;
      }
    }
    &-iconBlock {
      cursor: pointer;
      &-popup {
        position: absolute;
        background-color: white;
        color: #414042;
        padding: 0.2rem;
        margin-left: 1rem;
        border-radius: 15px;
        box-shadow: 0px 0px 20px rgb(218, 218, 218);
        text-transform: none;
        margin: 3px 0px 0px 42px;

        &.open {
          max-width: 185px;
          padding: 12px;
          width: max-content;
          border-radius: 15px;
          border-top-left-radius: 3px;
          transition: 0.05s cubic-bezier(0.1, -0.93, 0.57, 0.1);
          text-transform: none;
          font-family: 'Nexa Regular', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 19.6px;
        }
        &.closed {
          opacity: 0;
          transition: none;
        }
      }
    }
  }
  &-editIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: end;
    padding: 2.5rem;
    left: 0%;
    top: 0%;
    opacity: 0;
    z-index: 1;

    &:hover {
      opacity: 1;
      transition: 0.5s;
      z-index: 1;
    }
  }

  &-text {
    font-size: 14px;
    line-height: 18px;
    color: #414042;
  }
}

@font-face {
  font-family: 'Nexa Bold';
  src: url(./fonts/Nexa-Bold.ttf);
}

@font-face {
  font-family: 'Nexa Light';
  src: url('./fonts/Nexa-Light.ttf');
}

@font-face {
  font-family: 'Nexa Regular';
  src: url('./fonts/NexaRegular.ttf');
}

@font-face {
  font-family: 'Nexa Thin';
  src: url('./fonts/NexaThin.ttf');
}

@font-face {
  font-family: 'Nexa Heavy';
  src: url('./fonts/NexaHeavy.ttf');
}

@font-face {
  font-family: 'Nexa XBold';
  src: url('./fonts/Nexa-XBold.ttf');
}

@font-face {
  font-family: 'Nexa Book';
  src: url('./fonts/Nexa-Book.ttf');
}

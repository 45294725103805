$toggle-animation-duration: 0.6s;

.sidebar {
  width: 72px;
  background-color: #070b12;
  color: #ffffff;
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  &.hidden {
    display: none;
  }

  .sidebar__support {
    img {
      width: 16px;
      height: 16px;
    }
    span {
      display: none;
    }
  }
  &.closed &__toggle {
    transform: rotate(180deg);
  }

  &__toggle {
    cursor: pointer;
    position: absolute;
    right: 2rem;
    z-index: 10000;
    transition: all $toggle-animation-duration;
  }

  &__logo {
    width: 24px;
    height: 24px;
  }

  &__nav {
    display: flex;
    flex-direction: column;

    &-link {
      margin: 1.5rem 0 1.5rem 0;
      display: flex;
      align-items: center;
      color: #ced4eb;
      text-decoration: none;
      font-family: 'Nexa Bold', sans-serif;

      &.active {
        color: #fff;
      }

      &.disabled {
        color: #b8b8b8;
      }

      &__icon {
        position: relative;
        margin-right: 1.5rem;
        width: 25px;
        height: 30px;
        &__wrapper {
          width: 30px;
        }
        &__tooltip {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: max-content;
          min-width: 65px;
          margin-left: 42px;
          padding: 10px 15px 10px 10px;
          border-radius: 5px;
          z-index: 10;
          background-color: #0d111a;
          box-shadow: -1px -1px 20px rgb(147 147 147 / 50%);
          animation: fade-in-tooltip 150ms ease-in-out;
          pointer-events: none;

          &::after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: 100%;
            border-top: 12px solid transparent;
            border-right: 6px solid #0d111a;
            border-bottom: 12px solid transparent;
          }
          &.inactive {
            display: none;
          }
          &__label {
            font-family: 'Nexa Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 12px;
            color: white;
          }
          @keyframes fade-in-tooltip {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &-support {
    position: absolute;
    cursor: pointer;
    bottom: 4.5rem;
  }
  &-version {
    position: absolute;
    cursor: default;
    bottom: 2rem;
    font-size: 10px;
    line-height: 16px;
    color: #ffffff;
    white-space: nowrap;
    font-family: 'Nexa Bold';
    font-weight: 700;
    img {
      margin: 0px 13px 3px 7px;
    }
  }
  @media screen and (max-width: $mobile) {
    &.closed {
      width: 8rem;
      padding: 1rem;
    }
    &__logo {
      margin: 4rem 0rem 10rem 0rem;
    }
    &__toggle {
      right: 4.18rem;
    }
  }
}

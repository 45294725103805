.no-permission {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9rem 0 14rem;

  &__text {
    color: #29335c;
    width: 30%;
    min-width: 40rem;

    h1 {
      color: #29335c;
      font-weight: 600;
    }

    a {
      color: #29335c;
      text-decoration: underline;
    }

    &-btn {
      margin-top: 2rem;
    }
  }

  img {
    width: 50%;
  }
}

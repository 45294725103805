.tooltip {
  &-open {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 85px;
    width: max-content;
    margin-left: 40px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    color: white;
    background-color: #5f6063;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    animation: fade-in-tooltip 0.1s cubic-bezier(0.42, 0, 1, 1);
    pointer-events: none;
    font-family: 'Nexa Bold';
    font-size: 12px;
    line-height: 12px;

    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 100%;
      border-top: 12px solid transparent;
      border-right: 6px solid #5f6063;
      border-bottom: 12px solid transparent;
    }

    animation: tooltip-enter 0.25s ease-in-out;
  }

  &-closed {
    display: none;
  }

  @keyframes tooltip-enter {
    0% {
      box-shadow: 0;
      opacity: 0;
    }
    50% {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      opacity: 0.3;
    }
    100% {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
  }
}

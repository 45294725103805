.table {
  z-index: -1;

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      border-radius: 50%;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background-color: #e7e8ec;
      }
    }
  }

  &__emails-analyzed {
    min-width: 9rem;
  }
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 7px;
  height: 27px;

  &__dot {
    background-color: #acbbf5;
    width: 4px;
    height: 4px;
    margin: 2px 0;
    border-radius: 50%;
  }

  &__popup {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 1000;
  }

  &__red {
    color: $red-primary;

    &-disabled {
      color: darken($color: $red-primary, $amount: 35);
      cursor: not-allowed;
    }
  }

  &__option {
    min-width: 15rem;
    margin: 1.5rem 0;
    text-transform: uppercase;

    &-disabled {
      color: #aaa;
    }

    &:hover {
      color: rgb(122, 178, 252);
      transition: 0.2s;
    }
  }

  &__disabled {
    color: #aaa;
    cursor: not-allowed;

    &:hover {
      color: #aaa;
    }
  }
}

.ant-table-thead > tr > th {
  color: #465eb5;
  font-family: 'Nexa XBold';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: #fff;
  padding: 16px 1px;

  &:nth-child(5) {
    text-align: center;
  }
  &:nth-child(6) {
    text-align: center;
  }
  &:nth-child(7) {
    text-align: center;
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}

.ant-table-tbody > tr > td {
  background-color: #fbfbff;

  &:nth-child(2) {
    min-width: 170px;
  }

  &:nth-child(3) {
    text-decoration: underline;
  }

  &:nth-child(4) {
    min-width: 190px;
  }

  &:nth-child(5) {
    text-align: center;
  }

  &:nth-child(6) {
    text-align: center;
    min-width: 111px;
  }

  &:nth-child(7) {
    min-width: 165px;
    text-align: center;
  }
}

.ant-table {
  font-size: 1.2rem;

  table {
    border-spacing: 0 0.6rem;
    cursor: default;
  }
}

.ant-table-tbody > tr > td {
  border: 0;
}

.ant-table-tbody > tr > td {
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 1.6rem 2rem;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1.6rem 2rem;
  }
}

.ant-table-tbody > .disabled > td {
  color: rgba(65, 64, 66, 0.4);
  font-family: 'Nexa Bold', sans-serif;
}

.ant-spin-container > ul {
  display: none;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  display: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border: 1px solid #acbbf5;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #acbbf5;
  border-color: #acbbf5;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fbfbff;
}

.ant-checkbox-inner::after {
  top: 40%;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 4px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0.6) translate(-105%, -55%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-inner::after {
  left: 22%;
  width: 9px;
  height: 7px;
}

.ant-table-body {
  height: 580px;
}

.animated-row {
  animation: rowSlideIn 1.5s;
}

.info-tooltip {
  background-color: #fbfbff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border-top-left-radius: 0;
  position: absolute;
  padding: 1.1rem 2.8rem;
  color: #000;
  text-transform: capitalize;
  font-weight: 600;
  z-index: 1000;
  animation: tooltip-enter 0.35s ease-in-out;
}

@keyframes tooltip-enter {
  0% {
    box-shadow: 0;
    opacity: 0;
  }

  100% {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
}

.user__seats {
  min-width: 121px;
}

.table-url {
  width: 25%;
  max-width: 220px !important;
  padding-right: 20px !important;
}

.invitation-status {
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f0f2f9;
  border-radius: 3.6rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  color: $text-primary;

  &__tooltip {
    width: 25rem;
    right: 2rem;
    top: 80%;
    border-top-left-radius: 2rem;
    border-top-right-radius: 0;
    text-align: left;
    text-transform: none;
  }
}

@keyframes rowSlideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

// colors

$text-primary: #414042;
$blue-primary: #000f35;
$green-primary: #32b76c;
$blue-secondary: #394577;
$red-primary: #f26065;

// screen-sizes

$smaller: 320px;
$mob: 576px - 1;
$mobile: 768px - 1;
$tablet: 992px - 1;
$laptop: 1166px;
$desktop1280: 1280px;
$desktop1440: 1440px;
$desktop2000: 2000px;

// animation

$fadein: animationFadeIn 0.3s ease-in;
@keyframes animationFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// mixins

@mixin userSelectDisable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

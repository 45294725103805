@import './assets/fonts';
@import './styles/variables';
@import './components/components';
@import './pages/pages';
@import '~antd/dist/antd.css';

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Nexa Regular', sans-serif;
  color: $text-primary;
}

.App {
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;

  &__feedback {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    cursor: pointer;
  }
}

.page {
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  background-color: #fff;
  padding: 5rem 4rem;
  position: relative;

  &__content {
    padding: 2.8rem 0;
  }
}
@media screen and (max-width: $mobile) {
  .page {
    padding: 3rem 1rem;
  }
}
@media screen and (min-width: $desktop1280) and (max-width: ($desktop1440 - 1)) {
  .page {
    padding: 5rem 4rem;
  }
}
@media screen and (min-width: $desktop1440) and (max-width: ($desktop2000 - 1)) {
  .page {
    padding: 5rem 5rem;
  }
}
@media screen and (min-width: $desktop2000) {
  .page {
    padding: 5rem 25rem;
  }
}

.routes {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active
  .ant-skeleton-content
  .ant-skeleton-paragraph
  > li {
  animation-duration: 0.7s;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-duration: 0.7s;
}
::-webkit-scrollbar {
  width: 4px;
}
::-moz-scrollbar {
  width: 4px;
}
::-ms-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-moz-scrollbar-track {
  background: #ffffff;
}
::-ms-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f2f9;
}
::-moz-scrollbar-thumb {
  background: #f0f2f9;
}
::-ms-scrollbar-thumb {
  background: #f0f2f9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(182, 182, 182);
}
::-moz-scrollbar-thumb:hover {
  background: rgb(182, 182, 182);
}
::-ms-scrollbar-thumb:hover {
  background: rgb(182, 182, 182);
}

.active-status {
  text-transform: capitalize;
  padding: 0.5rem 0;
  border-radius: 3.6rem;
  font-size: 1.2rem;
  text-align: center;
  width: 6.5rem;
  margin-left: -4.5px;

  &__active {
    color: $green-primary;
    background-color: #ddf1e6;
  }

  &__inactive {
    color: $red-primary;
    background-color: #ffdfe0;
  }
}

.btn {
  font-family: 'Nexa Bold', sans-serif;
  border-radius: 1.5rem;
  border: 0;
  padding: 1.2rem 0;
  cursor: pointer;
  font-size: 1.6rem;
  display: block;
  width: 100%;
  transition: all 0.5s;

  &-common {
    background-color: $blue-secondary;
    color: #fff;

    &:hover {
      background-color: darken($blue-secondary, 3);
    }
    &:disabled {
      background: #dbdbdb;
      cursor: default;
    }
  }

  &-text {
    background-color: inherit;
    color: $blue-secondary;
    padding: 0;

    &:hover {
      color: darken($blue-secondary, 5);
    }
  }
}

.image-cropper {
  width: 100%;
  height: 40rem;

  &__cropper {
    width: 100%;
    height: 70%;
    position: relative;

    img {
      height: 100%;
    }
  }
  &__btn-block {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem 0;
    width: 100%;
    min-height: 100px;
    &__btn {
      width: 100%;
    }

    &:last-child {
      margin: 0;
    }
  }

  .reactEasyCrop_CropAreaGrid::before,
  .reactEasyCrop_CropAreaGrid::after {
    border: 0 !important;
  }

  .reactEasyCrop_CropArea,
  img {
    border: 1px solid #6bc6ed !important;
  }

  .reactEasyCrop_Container {
    border-radius: 1.5rem;
    margin: 2rem 0;
  }
}
.ant-slider-track {
  background-color: #394577;
}
.ant-slider-handle {
  border: solid 3px #394577;
}
.ant-spin-dot-item {
  background-color: #394577;
}
.ant-spin-container {
  min-width: 360px;
}

$modal-animation-duration: 0.2s;

.modal {
  &__overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__window {
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 444px;
    background-color: #fff;
    border-radius: 6px;
    padding: 4rem;

    .close-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      transform: translate(15%, -16%);
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
    &-bordergradient {
      position: absolute;
      top: -1px;
      right: 0px;
      height: 8px;
      width: 100%;
      border-radius: 6px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background: linear-gradient(269.44deg, #5f80f5 0.93%, #9066b8 55.85%),
        #9066b8;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Nexa Bold', sans-serif;
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
      cursor: default;
    }
  }

  &-enter {
    opacity: 0.5;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity $modal-animation-duration;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 70px;

  &__title {
    font-family: 'Nexa Bold', sans-serif;
    text-align: center;
    display: flex;
    justify-content: space-between;
    min-width: 240px;
    cursor: default;

    &__no-data-icon {
      animation: animationNoDataIcon 1.5s infinite;
    }
  }

  @keyframes animationNoDataIcon {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }

  &__user {
    position: relative;
    display: flex;
    align-items: center;

    &-name {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      cursor: default;
    }

    &-avatar {
      margin-left: 10px;
      width: 40px;
      height: 40px;
    }

    &-popup {
      position: absolute;
      bottom: -2px;
      right: 0;
      transform: translateY(100%);
      font-size: 14px;

      &__option {
        margin: 2rem 0;
      }
    }
  }
  &__analytics-block {
    position: relative;
    display: flex;
    flex-direction: column;

    &__select-button-block {
      display: flex;
      flex-direction: row;

      &__downloadButton {
        background-color: #394577;
        min-width: 78px;
        height: 32px;
        border-radius: 8px;
        border: none;
        color: white;
        font-family: 'Nexa Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-left: 10px;

        &:disabled {
          background-color: #cdcdcd;
        }
      }
    }
    &__warn-message {
      position: absolute;
      top: 115%;
      max-width: 280px;
      width: max-content;
      color: #eba53a;
      font-family: 'Nexa Regular';
      font-weight: 100;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
    &__error-message {
      position: absolute;
      top: 111%;
      max-width: 250px;
      color: #f02501;
      font-family: 'Nexa Regular';
      font-weight: 100;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 26px;
    }
    &__user {
      display: none;
    }
    &__analytics-block__warn-message {
      top: 108%;
      font-size: 16px;
      line-height: 15px;
    }
    &__analytics-block__error-message {
      top: 108%;
      font-size: 16px;
      line-height: 15px;
    }
  }
}

.checkbox {
  position: relative;
  display: block;
  width: 1.4rem;
  height: 1.4rem;

  &__control {
    display: none;

    &:checked + div {
      background: #acbbf5 url('../../assets/icons/check.svg') center no-repeat;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #acbbf5;
    border-radius: 2px;
  }
}

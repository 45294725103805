.org-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  padding: 0px 10px 0px 10px;
  cursor: default;
  max-height: 50px;
  overflow: hidden;

  &__label {
    font-family: 'Nexa Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #414042;

    &-header {
      font-family: 'Nexa XBold';
      font-size: 16px;
      font-weight: 800;
      line-height: 21px;
      color: #465eb5;
    }
  }

  &__toggle {
    position: relative;
    display: block;
    background-color: #d7d7ee;
    border-radius: 13px;
    width: 48px;
    height: 24px;
    cursor: pointer;

    &-circle {
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translate(0, -50%);
      background-color: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      transition: all 0.2s ease;
    }

    &-control {
      width: 0px;
      border: 0;

      &:checked + .org-row__toggle-circle {
        top: 50%;
        left: 100%;
        transform: translate(calc(-100% - 1.8px), -50%);
        background-color: #76c679;
      }
    }
  }
}

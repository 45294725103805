$border-color: #5f80f5;
$text-color: #414042;
$background-color: #f0f2f9;
$background-color-hover: rgb(231, 232, 248);

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  width: 54%;
  z-index: 100;
  cursor: pointer;
  font-family: 'Nexa Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  animation: fadeIn 0.4s linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  &__input-control {
    width: 100%;
    padding: 1.5rem 5rem;
    border: 0;
    background-color: #f0f2f9;
    border-radius: 1.5rem;
    border: 1px solid #f0f2f9;

    &.open {
      border: 1px solid $border-color;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none;
    }

    &.error {
      border: 1px solid #f26065;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &__input-icon {
    position: absolute;
    top: 27%;
    left: 1.7rem;
  }

  &__error {
    color: $red-primary;
    font-size: 1.4rem;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);

    & + input {
      border-color: $red-primary;
    }
  }

  // dropped items

  &__dropped-overflow-container {
    overflow-y: auto;
    overflow-x: visible;
    height: fit-content;
    width: 100%;
    top: 45px;
    position: absolute;
    cursor: pointer;
  }

  &__dropped-block {
    max-height: 200px;
    background-color: $background-color;
    padding: 5px 0px;
    z-index: 1;
    transition: all 0.1s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    border: 1px solid $border-color;
    border-radius: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top: none;

    &.closed {
      transform: translateY(-100%);
      padding-top: 0;
      animation: closeDropDownAnimation 0.10s ease-out forwards;
    }

    @keyframes closeDropDownAnimation {
      0% {
        height: 100%;
        opacity: 1;
      }
      100% {
        height: 0px;
        opacity: 0;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-moz-scrollbar {
      display: none;
    }
    &::-ms-scrollbar {
      display: none;
    }
  }

  .drop-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 0px 5px 15.6%;
    min-height: 30px;

    &:hover {
      background: $background-color-hover;
      border-radius: 8px;
    }
    &.disabled {
      color: rgb(207, 207, 207);
      pointer-events: none;
      cursor: default;
    }
  }
}

.avatar {
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &__photo {
    transition: all 0.7s;
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    background-color: #f0f2f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nexa Bold', sans-serif;
  }

  &__active {
    width: 16px;
    height: 16px;
    background-color: $green-primary;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.active {
    .avatar__hover {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: #f0f2f9;
      width: 40px;
      height: 40px;
      font-size: 10px;
      transition: all 0.7s;
      transform: translateY(100%);
    }

    &:hover {
      .avatar__photo {
        transform: translateY(-100%);
      }

      & .avatar__hover {
        transform: translateY(0);
      }
    }
  }
}

#avatar-upload {
  display: none;
}
